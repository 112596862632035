










































































import {Vue, Component, Prop} from 'vue-property-decorator'
import {
  BForm,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BButton, BCol, BRow, BCard, BInputGroup, BCardTitle,
  BModal, VBModal, BSpinner,
} from "bootstrap-vue";
import {VueGoodTable} from 'vue-good-table'
import {NotifyInfo, NotifySuccess} from "@/helpers/Toast";
import {VForm} from "@/global";

@Component({
  components: {
    VueGoodTable,
    BForm,
    BAvatar,
    BBadge,
    BPagination,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BModal,
    BSpinner
  },
  directives: {
    'b-modal': VBModal,
  }
})
export default class AccessLists extends Vue {
  $refs!: {
    createForm: VForm
  }

  columns = [
    {
      label: 'Name',
      field: 'name',
    },
  ]

  rows: MaintenanceAPI.AccessLists.Item[] = [];

  async created() {
    await this.getItems();
  }

  async getItems() {
    this.rows = await this.$api.maintenance.accesslists.GetList();
  }

  async goTo(params) {
    await this.$router.push({
      name: "maintenance-accesslist",
      params: {
        identifier: params.row.type
      }
    })
  }



  // Create Model
  createModel = {
    loading: false,
    type: null,
    name: null,
  }

  async create(bvModalEvt) {
    this.createModel.loading = true;
    bvModalEvt.preventDefault();

    if(!await this.$refs.createForm.validate()) {
      this.createModel.loading = false;
      return;
    }

    const checkIfTypeExists = await this.$api.maintenance.accesslists.Get(this.createModel.type);
    if(checkIfTypeExists != null)
    {
      // @ts-ignore
      this.$refs.createForm.errors.Type.push("This Type already exists");
      return;
    }

    const res = await this.$api.maintenance.accesslists.Set(this.createModel.type, this.createModel.name, [], []);
    if(res != null)
      await this.$router.push({
        name: "maintenance-accesslist",
        params: {
          identifier: res.type
        }
      })

    this.createModel.loading = false;
  }

  cancel() {
    this.$refs.createForm.reset();
    this.$set(this, "createModel", {name: null, type: null})
  }
}
